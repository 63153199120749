import { TextArea, Panel, Label, Link } from '@ui5/webcomponents-react';
import "@ui5/webcomponents-icons/dist/alert.js";
import "@ui5/webcomponents-icons/dist/copy.js";
import "@ui5/webcomponents-icons/dist/arrow-bottom.js";
import "@ui5/webcomponents/dist/Button.js";

function handleChange(){
  var theResult = document.getElementById('oppresult');
  var theList = document.getElementById('oppsearch');
  var theLabel = document.getElementById('opplabel');
  var link = 'https://app2b.outreach.io/opportunities?queryFilters=%5B%5D&search=custom2%3A%22'
  var n = this.value;
  var lines = n.split('\n');
  let i = 0
  lines.forEach(function(line) {
    line = line.replace(/^0+/, '');//removes leading zeros
    if (line === '\n'){
      //console.log('extra space at the end')
      return;
    }
    if (line.length <= 1){
      //console.log('extra space at the end')
      return;
    }
    if (i === 0){
      //console.log('First Line is ' + line)
      link += line+'%22%20'
      i++
      return
    }
    if (i >= 1) {
      //console.log('Line is ' + line)
      link += 'OR%20custom2%3A%22'+line+'%22%20'
      i++
    }
    if (i > 102) {
      theResult.value = ''
      theResult.text = ''
      theList.value = ''
      theLabel.innerText = 'Opportunities entered: 0'
      i = 0
      alert('Limit of 100 lines exceeded')   
    }    
  })
  theLabel.innerText = 'Opportunities entered: '+ (i)
  theResult.value = link
  //console.log(n)
}


function onClear(){
  document.getElementById('oppresult').value = ''
  document.getElementById('oppsearch').text = ''
  document.getElementById('oppsearch').value = ''
  document.getElementById('opplabel').innerText = 'Opportunities entered: 0'
  //console.log("Button Clicked")
}



function copy() {
  const el = document.createElement('input');
  el.value = document.getElementById('oppresult').value
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  document.getElementById('oppresult').value = ''
  document.getElementById('oppsearch').text = ''
  document.getElementById('oppsearch').value = ''
  setTimeout(() => {
    document.getElementById('opplabel').innerText = 'Opportunities entered: 0'
  }, 2000);
  document.getElementById('opplabel').innerText = 'Link Generated!'
}
const openPage = () => {
  var textos = document.getElementById('oppresult').value
  if (textos.length < 3){
    alert('Please enter at least 1 Opportunity')
    return
  }
  window.open(textos, "_blank")
  console.log(textos)  
}
const placehold = 'One Opportunity per line. 100 Opps Max \nLeading zeros are removed automatically from link'

function Title() {
  return <div>
          <Panel className='panel' headerText='Opportunity Query Builder' collapsed="false">
          <ui5-title level="H4">Paste a list of Opportunities you want to search for.</ui5-title>
          
          <TextArea rows="10" Growing="true" placeholder={placehold} growingMaxLines="20" onInput={handleChange} id="oppsearch" />
          <Label id="opplabel">Opportunities entered: 0</Label>
          <br></br>
          <ui5-button class='orbtn' onClick={openPage} design="Emphasized">Open in Outreach</ui5-button>
          <ui5-button onClick={copy} design="Default">Copy Link</ui5-button>
          <ui5-button onClick={onClear} design="Negative">Clear</ui5-button>
          <br></br>
          <br></br>
          <ui5-title level="H6">Link Generated:</ui5-title>
          <TextArea id="oppresult" disabled />
          
          <br></br>
          <br></br>
          <br></br>
          </Panel>
  </div>;
}




export default Title;


