import { TextArea, Panel, Label, Link } from '@ui5/webcomponents-react';
import "@ui5/webcomponents-icons/dist/alert.js";
import "@ui5/webcomponents-icons/dist/copy.js";
import "@ui5/webcomponents-icons/dist/arrow-bottom.js";
import "@ui5/webcomponents/dist/Button.js";



function handleChange(){
  var theResult = document.getElementById('result');
  var theList = document.getElementById('search');
  var theLabel = document.getElementById('label');
  var link = 'https://app2b.outreach.io/accounts?queryFilters=%5B%5D&search=custom_id%3A%22'
  var n = this.value;
  var lines = n.split('\n');
  let i = 0
  lines.forEach(function(line) {
    line = line.replace(/^0+/, '');//removes leading zeros
    if (line === '\n'){
      //console.log('extra space at the end')
      return;
    }
    if (line.length <= 1){
      //console.log('extra space at the end')
      return;
    }
    if (i === 0){
      //console.log('First Line is ' + line)
      link += line+'%22%20'
      i++
      return
    }
    if (i >= 1) {
      //console.log('Line is ' + line)
      link += 'OR%20custom_id%3A%22'+line+'%22%20'
      i++
    }
    if (i > 102) {
      theResult.value = ''
      theResult.text = ''
      theList.value = ''
      theLabel.innerText = 'Accounts entered: 0'
      i = 0
      alert('Limit of 100 lines exceeded')   
    }    
  })
  theLabel.innerText = 'Accounts entered: '+ (i)
  theResult.value = link
  //console.log(n)
}

function onClear(){
  document.getElementById('result').value = ''
  document.getElementById('search').text = ''
  document.getElementById('search').value = ''
  document.getElementById('label').innerText = 'Accounts entered: 0'
  //console.log("Button Clicked")
}



function copy() {
  const el = document.createElement('input');
  el.value = document.getElementById('result').value
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  document.getElementById('result').value = ''
  document.getElementById('search').text = ''
  document.getElementById('search').value = ''
  setTimeout(() => {
    document.getElementById('label').innerText = 'Accounts entered: 0'
  }, 2000);
  document.getElementById('label').innerText = 'Link Generated!'
}

const openPage = () => {
  var textos = document.getElementById('result').value
  if (textos.length < 3){
    alert('Please enter at least 1 Account')
    return
  }
  window.open(textos, "_blank")
  console.log(textos)  
}

const placehold = 'One Account per line. 100 Accounts Max \nLeading zeros are removed automatically from link'


function Title() {

  return <div>
    
          <Panel className='panel' headerText='Account Query Builder' collapsed="false">
          <ui5-title level="H4">Paste a list of the accounts you want to search for.</ui5-title>
          <Label id="label"></Label>
          <TextArea rows="10" Growing="true" placeholder={placehold} growingMaxLines="20" onInput={handleChange} id="search" />
          <Label id="label">Accounts entered: 0</Label>
          <br></br>
          <ui5-button class='orbtn' onClick={openPage} design="Emphasized">Open in Outreach</ui5-button>
          <ui5-button onClick={copy} design="Default">Copy Link</ui5-button>
          <ui5-button onClick={onClear} design="Negative">Clear</ui5-button>
          <br></br>
          <br></br>
          <ui5-title level="H6">Link Generated:</ui5-title>
          <TextArea id="result" disabled />
          <br></br>
          <br></br>
          <br></br>
          </Panel>
          
  </div>;
}




export default Title;


