import {ThemeProvider, ShellBar, Label, Link} from '@ui5/webcomponents-react';
import "@ui5/webcomponents-icons/dist/action.js";
import React from 'react';
import './App.css';
import Title from './Title';
import Opps from './Opps';


function App() {
  return (
    <ThemeProvider>
      
      <ShellBar primaryTitle="Outreach @ SAP Link Creator"><img slot="logo" src="https://sap.github.io/ui5-webcomponents/assets/images/sap-logo-svg.svg"/></ShellBar>
      <Title></Title>
      <Opps></Opps>
      <ShellBar></ShellBar>
        <Label className='bottomlabel'>Created by Nicolas Bergara<Link href="https://tools.nicasys.ar" target="_blank"><ui5-icon name="action"></ui5-icon></Link></Label>
    </ThemeProvider>
  );
}

export default App;
